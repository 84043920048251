import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import CloudinaryUpload from '../../services/Cloudinary'; // Import the Cloudinary upload component
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const CreateProjectForm = ({ project, closeModal, refreshProjects }) => {
  const [title, setTitle] = useState(project?.title || '');
  const [description, setDescription] = useState(project?.description || ''); // Use Quill for this
  const [features, setFeatures] = useState(project?.features || ['']);
  const [imgSrc, setImgSrc] = useState(project?.img_src || '');
  const [status, setStatus] = useState(project?.status || '');
  const [link, setLink] = useState(project?.link || '');
  const [monthlyPrice, setMonthlyPrice] = useState(project?.monthlyPrice || '');
  const [annualPrice, setAnnualPrice] = useState(project?.annualPrice || '');
  const [releaseDate, setReleaseDate] = useState(project?.releaseDate || '');
  const [userCount, setUserCount] = useState(project?.userCount || ''); // New state for number of users
  const [loading, setLoading] = useState(false);

  // Add a new empty feature field
  const addFeatureField = () => {
    setFeatures([...features, '']);
  };

  // Update feature value
  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };

  // Handle form submission for create or update
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (project) {
        // Update project
        await updateDoc(doc(firestore, 'projects', project.id), {
          title,
          description,
          features,
          img_src: imgSrc,
          status,
          link,
          monthlyPrice,
          annualPrice,
          releaseDate,
          userCount, // Save user count
        });
      } else {
        // Create new project
        await addDoc(collection(firestore, 'projects'), {
          title,
          description,
          features,
          img_src: imgSrc,
          status,
          link,
          monthlyPrice,
          annualPrice,
          releaseDate,
          userCount, // Save user count
          dateAdded: new Date().toISOString(),
          votes: 0, // Set initial votes to 0
        });
      }
      refreshProjects(); // Refresh project list
      closeModal(); // Close the modal
    } catch (error) {
      console.error('Error saving project:', error);
    } finally {
      setLoading(false);
    }
  };

  const quillModules = {
    toolbar: [['bold'], [{ 'list': 'bullet' }]], // Enable bold text and bullet lists
  };

  const quillFormats = ['bold', 'list']; // Restrict formats to bold and list

  return (
    <form onSubmit={handleSubmit} className="mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">{project ? 'Edit Project' : 'Create New Project'}</h2>
  
      {/* Title */}
      <div className="mb-4">
        <label className="block text-gray-700">Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter project title"
          required
        />
      </div>
  
      {/* Description with Quill */}
      <div className="mb-8">
        <label className="block text-gray-700 mb-2">Description</label>
        <div className="border rounded-lg p-2">
          <ReactQuill
            value={description}
            onChange={setDescription}
            modules={quillModules}
            formats={quillFormats}
            className="h-40"
            placeholder="Enter project description"
          />
        </div>
      </div>
  
      {/* Features */}
      <div className="mb-4">
        <label className="block text-gray-700">Features</label>
        {features.map((feature, index) => (
          <div key={index} className="mb-2 flex">
            <input
              type="text"
              value={feature}
              onChange={(e) => handleFeatureChange(index, e.target.value)}
              className="w-full p-2 border rounded-lg"
              placeholder={`Feature ${index + 1}`}
            />
          </div>
        ))}
        <button
          type="button"
          onClick={addFeatureField}
          className="bg-indigo-500 text-white px-4 py-2 rounded mt-2"
        >
          Add Another Feature
        </button>
      </div>
  
      {/* Image Upload */}
      <div className="mb-4">
        <label className="block text-gray-700">Project Image</label>
        <CloudinaryUpload setImageUrl={setImgSrc} />
      </div>
  
      {/* Status */}
      <div className="mb-4">
        <label className="block text-gray-700">Status</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-full p-2 border rounded-lg"
          required
        >
          <option value="">Select status</option>
          <option value="Released">Released</option>
          <option value="In Development">In Development</option>
          <option value="Planned">Planned</option>
        </select>
      </div>
  
      {/* Pricing */}
      <div className="mb-4">
        <label className="block text-gray-700">Monthly Price</label>
        <input
          type="number"
          value={monthlyPrice}
          onChange={(e) => setMonthlyPrice(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter monthly price"
        />
      </div>
  
      <div className="mb-4">
        <label className="block text-gray-700">Annual Price</label>
        <input
          type="number"
          value={annualPrice}
          onChange={(e) => setAnnualPrice(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter annual price"
        />
      </div>
  
      {/* Release Date */}
      <div className="mb-4">
        <label className="block text-gray-700">Release Date</label>
        <input
          type="date"
          value={releaseDate}
          onChange={(e) => setReleaseDate(e.target.value)}
          className="w-full p-2 border rounded-lg"
        />
      </div>
  
      {/* User Count */}
      <div className="mb-4">
        <label className="block text-gray-700">User Count</label>
        <input
          type="number"
          value={userCount}
          onChange={(e) => setUserCount(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter number of users"
        />
      </div>
  
      {/* Link */}
      <div className="mb-4">
        <label className="block text-gray-700">Project Link</label>
        <input
          type="url"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter project link"
        />
      </div>
  
      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full"
        disabled={loading}
      >
        {loading ? 'Saving...' : project ? 'Update Project' : 'Create Project'}
      </button>
    </form>
  );
};

export default CreateProjectForm;