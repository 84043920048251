// src/services/projectService.js
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase"; // Ensure correct Firestore import
import { doc, updateDoc, getDoc } from 'firebase/firestore';

export const getProjects = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestore, "projects"));
    const projects = [];
    querySnapshot.forEach((doc) => {
      projects.push({ id: doc.id, ...doc.data() }); // Fetch the document ID and data
    });
    return projects;
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }
};
  
// Fetch votes for a project
export const fetchVotesForProject = async (projectId) => {
    const projectRef = doc(firestore, 'projects', projectId);
    const projectDoc = await getDoc(projectRef);
  
    if (!projectDoc.exists()) return { totalVotes: 0, users: [] };
  
    const projectData = projectDoc.data();
    return {
      totalVotes: projectData.votes?.length || 0,
      users: projectData.votes || [],
    };
  };

  
// Vote for a project
export const voteForProject = async (projectId, userId) => {
    const projectRef = doc(firestore, 'projects', projectId);
    const projectDoc = await getDoc(projectRef);
  
    if (projectDoc.exists()) {
      const projectData = projectDoc.data();
      const updatedVotes = [...(projectData.votes || []), userId];
  
      // Update project document with the new vote
      await updateDoc(projectRef, {
        votes: updatedVotes,
      });
    }
  };