import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Firestore instance
import NavBar from '../../common/components/NavBar';
import GuidesSidebar from '../ui/GuidesSidebar';
import GuideContent from '../ui/GuideContent';
import Footer from '../../common/components/Footer';

const GuidesPage = () => {
  const [guidesData, setGuidesData] = useState([]);
  const [currentGuide, setCurrentGuide] = useState(null);
  const sidebarRef = useRef();

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        // Fetch all guides from Firestore
        const guidesCollection = await getDocs(collection(firestore, 'guides'));
        const guides = guidesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGuidesData(guides);

        // Set the first guide as default, if any exist
        if (guides.length > 0) {
          setCurrentGuide(guides[0]);
        }
      } catch (error) {
        console.error('Error loading guides:', error);
      }
    };

    fetchGuides();
  }, []);

  const handleGuideSelect = (guide) => {
    setCurrentGuide(guide);
    closeSidebar(); // Close sidebar on mobile after selecting a guide
  };

  const toggleSidebar = () => {
    if (sidebarRef.current) {
      sidebarRef.current.classList.toggle('-translate-x-full');
      sidebarRef.current.classList.toggle('translate-x-0');
    }
  };

  const closeSidebar = () => {
    if (sidebarRef.current && window.innerWidth < 768) {
      sidebarRef.current.classList.add('-translate-x-full');
      sidebarRef.current.classList.remove('translate-x-0');
    }
  };

  return (
    <div className="bg-white font-roboto">
      <NavBar />
      <div className="pt-20 min-h-screen flex">
        {/* Sidebar */}
        <GuidesSidebar
          guidesData={guidesData}
          onGuideSelect={handleGuideSelect}
          sidebarRef={sidebarRef}
        />
        {/* Main content */}
        <main className="flex-1 p-8 bg-white min-h-screen ml-0 md:ml-[300px]">
          {/* Sidebar Toggle Button for Mobile */}
          <button
            onClick={toggleSidebar}
            className="md:hidden flex items-center text-gray-600 mt-4 fixed top-16 left-4 z-30"
          >
            <svg
              id="chevron"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2 transform"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                id="chevron-path"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 5l-7 7-7-7"
              />
            </svg>
            Toggle Sidebar
          </button>
          {currentGuide ? (
            <GuideContent guide={currentGuide} />
          ) : (
            <p>Select a guide from the sidebar.</p>
          )}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default GuidesPage;