// src/App.js
import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './app/pages/Home';
import LoginPage from './common/pages/LoginPage';
import Signup from './common/containers/Auth/Signup';
import GuidesPage from './app/pages/Guides';
import CreateProjectForm from './app/projects/CreateProjectForm';
import ProjectList from './app/projects/ProjectList';
import GuideList from './app/guides/Guidelist'; // Import GuideList
import PrivateRoute from './common/components/PrivateRoute';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/guides" element={<GuidesPage />} />

      {/* Private route for GuideList */}
      <Route
        path="/admin-guides"
        element={
          <PrivateRoute>
            <GuideList />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoute>
            <ProjectList />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;