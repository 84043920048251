// src/common/contexts/AuthContext.js
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { auth, firestore } from "../../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);

      if (user) {
        // Fetch the user document from Firestore
        const userDocRef = doc(firestore, "accounts", user.uid);
        const userDoc = await getDoc(userDocRef);

        // Check subscription status from the customers collection
        const customerRef = doc(collection(firestore, "customers"), user.uid);
        const customerDoc = await getDoc(customerRef);
        const customerData = customerDoc.data();
        setIsSubscribed(customerData?.activeSubscription || false);

        // Set account data and ensure the admin field is captured
        if (userDoc.exists()) {
          setAccountData({
            ...userDoc.data(),
            id: userDoc.id,
            admin: userDoc.data().admin === "true", // Ensure admin is a boolean
          });
        } else {
          // If no document exists, create a new one
          await setDoc(userDocRef, {
            admins: [user.email],
            teamMembers: [],
            admin: "false", // Default to false for non-admin users
          });
          setAccountData({
            admins: [user.email],
            teamMembers: [],
            admin: false,
            id: userDocRef.id,
          });
        }
      } else {
        // If no user is logged in, clear account data
        setAccountData(null);
        setIsSubscribed(false);
      }

      setLoading(false); // Stop loading after fetching user data
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    accountData,
    isSubscribed,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}