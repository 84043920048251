import React, { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const Hero = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  // Check session storage for dismissal status on component mount
  useEffect(() => {
    const dismissed = sessionStorage.getItem('dismissed');
    if (dismissed) {
      setIsDismissed(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Post email to webhook endpoint
    try {
      await fetch('https://hook.us1.make.com/elswqz6lpe5fklliq6vwgxbttqfd2tlz', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      setEmail(''); // Clear the email field
      setSubmitted(true); // Show thank you message
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    sessionStorage.setItem('dismissed', 'true'); // Save dismissal status in session storage
  };

  return (
    <section
      className="w-full h-[800px] bg-cover bg-center relative"
      style={{ backgroundImage: "url('https://res.cloudinary.com/bettercast/image/upload/v1728737307/businessdaddy/v4uguotjmuteslp3tqmw.png')" }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center">
        <h1 className="text-5xl font-bold text-white">Business Daddy Studios</h1>
        <p className="mt-4 text-lg text-white">Ben, Hunter and ChatPGT are building 20 apps in 20 weeks.</p>


        {/* Hide the form if the alert has been dismissed */}
        {!isDismissed && (
          !submitted ? (
            <form onSubmit={handleSubmit} className="mt-6">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 rounded-l-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className="bg-indigo-600 text-white py-2 px-4 rounded-r-md hover:bg-indigo-700"
              >
                Sign Up
              </button>
            </form>
          ) : (
            <div className="rounded-md bg-green-50 p-4 mt-6">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">You've Signed Up!</h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>
                      Thanks for being a part of our 20 apps in 20 weeks challenge! We will let you know
                      as new apps are released and any other interesting news. Don't forget to join the
                      Discord if you want to get more involved.
                    </p>
                  </div>
                  <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <a
                        href="https://discord.gg/gzXxaVWcR6"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                      >
                        Join Discord
                      </a>
                      <button
                        onClick={handleDismiss}
                        className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Hero;