import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase'; // Import Firebase auth
import { signOut } from 'firebase/auth';

const NavBar = ({ onLoginClick }) => {
  const [user, setUser] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to toggle mobile menu

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return unsubscribe; // Clean up the subscription on unmount
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Successfully signed out
      setUser(null);
    });
  };

  // Function to toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md fixed w-full z-10">
      <div className="container mx-auto px-4 flex items-center justify-between h-20">
        {/* Logo and Title */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img
              src="https://res.cloudinary.com/bettercast/image/upload/v1728737295/businessdaddy/fezm6dxyqnbscofnz8el.png"
              alt="Business Daddy Logo"
              className="h-12"
            />
          </Link>
          <Link to="/">
            <span className="text-2xl font-semibold">Business Daddy Studio</span>
          </Link>
        </div>

        {/* Desktop Navigation Links */}
        <div className="hidden md:flex items-center space-x-8">
        
          <a href="https://discord.gg/gzXxaVWcR6" className="text-gray-600 hover:text-gray-900">Support</a>
          <Link to="/guides" className="text-gray-600 hover:text-gray-900">Guides</Link>
        </div>

        {/* Mobile Hamburger Icon */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="focus:outline-none text-gray-600 hover:text-gray-900"
          >
            {/* Hamburger Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu - Visible when isMobileMenuOpen is true */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white px-4 py-2">
        
          <a href="https://discord.gg/gzXxaVWcR6" className="block py-2 text-gray-600 hover:text-gray-900">Support</a>
          <Link to="/guides" className="block py-2 text-gray-600 hover:text-gray-900">Guides</Link>
        </div>
      )}
    </nav>
  );
};

export default NavBar;