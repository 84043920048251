import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../common/components/NavBar';
import Hero from '../ui/Hero';
import AboutUs from '../ui/AboutUs';
import Card from '../projects/Card';
import Footer from '../../common/components/Footer';
import { getProjects, voteForProject } from '../../services/ProjectService';

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]); // Add state for filtered projects
  const [loading, setLoading] = useState(true); // Add loading state
  const [activeFilter, setActiveFilter] = useState(''); // Track active filter
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Fetching projects...');
    getProjects()
      .then((data) => {
        console.log('Fetched projects:', data);
        setProjects(data);
        setFilteredProjects(data); // Initially show all projects
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching projects
      });
  }, []);

  const handleVote = (projectId) => {
    const user = localStorage.getItem('user');
    console.log('Handle vote for project:', projectId);
    if (!user) {
      console.log('User not logged in, redirecting to login page');
      navigate('/login');
    } else {
      console.log('User is logged in, voting for project');
      voteForProject(projectId, user);
    }
  };

  // Filter projects based on the status
  const filterProjects = (status) => {
    setActiveFilter(status); // Set the active filter
    if (status === '') {
      // Show all projects if no filter is selected
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter((project) => project.status === status);
      setFilteredProjects(filtered);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar onLoginClick={() => navigate('/login')} />
      <Hero />
      <AboutUs />
      <section className="py-20 bg-gray-100 flex-grow">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-10">Projects</h2>

{/* Filter Buttons */}
<div className="flex flex-col sm:flex-row justify-center sm:space-x-8 space-y-4 sm:space-y-0 mb-10">
  <button
    className={`px-6 py-2 rounded-lg text-sm font-semibold ${
      activeFilter === '' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
    }`}
    onClick={() => filterProjects('')}
  >
    All Projects
  </button>
  <button
    className={`px-6 py-2 rounded-lg text-sm font-semibold ${
      activeFilter === 'Released' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
    }`}
    onClick={() => filterProjects('Released')}
  >
    Released
  </button>
  <button
    className={`px-6 py-2 rounded-lg text-sm font-semibold ${
      activeFilter === 'In Development' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
    }`}
    onClick={() => filterProjects('In Development')}
  >
    In Development
  </button>
  <button
    className={`px-6 py-2 rounded-lg text-sm font-semibold ${
      activeFilter === 'Planned' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
    }`}
    onClick={() => filterProjects('Planned')}
  >
    Planned
  </button>
</div>

          {/* Loading Animation */}
          {loading ? (
            <div className="flex flex-col items-center">
              <h2 className="text-4xl font-bold text-gray-800 mb-4">Loading...</h2>
              <p className="text-base text-gray-600 mb-4">
                Please be patient, this can take a few seconds.
              </p>
              <div className="flex gap-2">
                <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce"></div>
                <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.2s]"></div>
                <div className="w-6 h-6 rounded-full bg-green-500 animate-bounce [animation-delay:-0.4s]"></div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 masonry-layout">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <Card key={project.id} project={project} onVote={handleVote} />
                ))
              ) : (
                <p className="text-center">No projects available.</p>
              )}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;