import React from 'react';
import { GlobeAltIcon, EnvelopeIcon } from '@heroicons/react/24/solid';

const TeamMemberCard = ({ name, image, description, website, twitter, linkedin, email, tiktok }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col h-full">
      {/* Ensure the image is aligned with the top of the card */}
      <img 
        src={image} 
        alt={name} 
        className="w-full h-64 object-cover object-top" // object-top ensures the top of the image is aligned with the top of the card
      />
      <div className="p-6 flex flex-col flex-grow">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">{name}</h2>
        <div className="flex-grow">
          <p className="text-gray-600 mb-4">{description}</p>
        </div>
        <div className="flex justify-left space-x-4 mt-6">
          {website && (
            <a href={website} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600">
              <GlobeAltIcon className="w-6 h-6" />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-500">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          )}
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-800">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
              </svg>
            </a>
          )}
          {email && (
            <a href={`mailto:${email}`} className="text-red-500 hover:text-red-600">
              <EnvelopeIcon className="w-6 h-6" />
            </a>
          )}
          {tiktok && (
            <a href={tiktok} target="_blank" rel="noopener noreferrer" className="text-black hover:text-gray-700">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 2c.733 0 1.457.07 2.164.207a9.776 9.776 0 01-1.004 2.81 4.104 4.104 0 00-2.164-.48A4.108 4.108 0 006.89 8.948 4.09 4.09 0 007.96 12c-.55.306-1.048.752-1.48 1.306a6.778 6.778 0 006.53 2.347 9.884 9.884 0 002.118 1.61A6.88 6.88 0 0112 21a7 7 0 01-7-7 6.97 6.97 0 011.408-4.096A4.09 4.09 0 006 9.043a4.108 4.108 0 01-1.48-5.96A9.776 9.776 0 0112 2z" />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;