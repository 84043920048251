import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot instead of ReactDOM
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './common/contexts/AuthContext'; // Import the AuthProvider

// Use createRoot in React 18
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider> {/* Wrap your app with AuthProvider */}
      <App />
    </AuthProvider>
  </BrowserRouter>
);