// src/components/Auth/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"; 

export default function PrivateRoute({ children }) {
  const { currentUser, accountData } = useAuth();

  console.log("Current User:", currentUser);
  console.log("Account Data:", accountData);

  // Check if user is logged in and is an admin
  if (!currentUser || !accountData?.admin) {
    return <Navigate to="/login" />;
  }

  return children;
}