import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import CreateGuideForm from './CreateGuideForm'; // Import form for creating/editing guides

export default function GuideList() {
  const [guides, setGuides] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState(null); // For editing
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [sortOrder, setSortOrder] = useState('asc'); // Sorting order for project name

  // Fetch all projects from Firestore
  const fetchProjects = async () => {
    const projectCollection = await getDocs(collection(firestore, 'projects'));
    const projectsData = projectCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setProjects(projectsData);
    return projectsData;
  };

  // Fetch all guides from Firestore and map them to their respective project names
  const fetchGuides = async (projectsData) => {
    const guideCollection = await getDocs(collection(firestore, 'guides'));
    const guidesData = guideCollection.docs.map((doc) => {
      const guideData = doc.data();
      const project = projectsData.find((project) => project.id === guideData.projectId);
      return { ...guideData, id: doc.id, projectName: project ? project.title : 'Unknown Project' };
    });

    setGuides(guidesData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const projectsData = await fetchProjects();
      await fetchGuides(projectsData);
    };
    fetchData();
  }, []);

  // Sorting function for project names
  const handleSort = () => {
    const sortedGuides = [...guides].sort((a, b) => {
      const comparison = a.projectName.localeCompare(b.projectName);
      return sortOrder === 'asc' ? comparison : -comparison;
    });
    setGuides(sortedGuides);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
  };

  // Delete guide
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this guide?')) {
      await deleteDoc(doc(firestore, 'guides', id));
      fetchProjects().then(fetchGuides); // Refresh guides
    }
  };

  // Edit guide (opens modal)
  const handleEdit = (guide) => {
    setSelectedGuide(guide);
    setShowModal(true);
  };

  // Add guide (opens modal with empty form)
  const handleAddGuide = () => {
    setSelectedGuide(null); // Ensure it's a new guide
    setShowModal(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-32">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Guides</h1>
          <p className="mt-2 text-sm text-gray-700">Manage guides content.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            onClick={handleAddGuide}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            Create New Guide
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 cursor-pointer"
                      onClick={handleSort} // Handle sorting by project name
                    >
                      Project
                      {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Guide Title</th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {guides.map((guide) => (
                    <tr key={guide.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {guide.projectName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500" style={{ paddingLeft: '36px' }}>
                        {guide.title}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-right">
                        <button
                          onClick={() => handleEdit(guide)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(guide.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing/creating guide */}
      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full">
              <button
                className="absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-700"
                onClick={() => setShowModal(false)}
              >
                ×
              </button>
              <CreateGuideForm guide={selectedGuide} closeModal={() => setShowModal(false)} refreshGuides={fetchGuides} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}