// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-6 text-center text-gray-400 w-full">
      <p>
        © 2024 Business Daddy. All rights reserved.{' '}
        <Link to="/terms" className="hover:underline">
          Terms & Conditions
        </Link>{' '}
        |{' '}
        <Link to="/privacy" className="hover:underline">
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
};

export default Footer;