import React from 'react';

const GuideContent = ({ guide }) => {
  return (
    <div>
      <h1 id="guide-title" className="text-3xl font-semibold mb-4 font-poppins mt-12">
        {guide.title} {/* Display guide title */}
      </h1>
      <div
        id="guide-content"
        dangerouslySetInnerHTML={{ __html: guide.content }} // Render HTML content directly from Firestore
        className="text-lg text-gray-700 mb-6 mt-12"
      />
    </div>
  );
};

export default GuideContent;