import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Assuming firestore is already set up

const GuidesSidebar = ({ guidesData, onGuideSelect, sidebarRef }) => {
  const [projects, setProjects] = useState([]);

  // Fetch project names from Firestore
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectCollection = await getDocs(collection(firestore, 'projects'));
        const projectsData = projectCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProjects(projectsData);
      } catch (error) {
        console.error("Error fetching projects: ", error);
      }
    };

    fetchProjects();
  }, []);

  // Group guides by projectId
  const groupedGuides = guidesData.reduce((acc, guide) => {
    const project = projects.find(p => p.id === guide.projectId);
    const projectName = project ? project.title : 'Unknown Project';
    if (!acc[projectName]) {
      acc[projectName] = [];
    }
    acc[projectName].push(guide);
    return acc;
  }, {});

  return (
    <aside
      ref={sidebarRef}
      id="sidebar"
      className="fixed inset-y-0 left-0 transform -translate-x-full md:translate-x-0 transition-transform duration-300 ease-in-out bg-gray-100 shadow-md z-20 mt-20 md:z-0 w-4/5 md:w-[300px] overflow-y-auto"
    >
      <div className="p-6">
        <h2 className="text-2xl font-semibold mb-4 font-poppins">Guides</h2>
        <hr className="mb-6" />

        {/* Group guides by project */}
        <div id="app-guides" className="space-y-6">
          {Object.keys(groupedGuides).length > 0 ? (
            Object.entries(groupedGuides).map(([projectName, guides]) => (
              <div key={projectName}>
                {/* Display the project name */}
                <h2 className="text-xl font-bold text-gray-800">
                  {projectName} {/* Project title */}
                </h2>
                <ul className="ml-4 mt-2 space-y-2">
                  {guides.map((guide) => (
                    <li key={guide.id}>
                      <button
                        onClick={() => onGuideSelect(guide)}
                        className="block text-gray-600 hover:text-indigo-600 text-left w-full"
                      >
                        {guide.title} {/* Guide title */}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No guides available.</p>
          )}
        </div>
      </div>
    </aside>
  );
};

export default GuidesSidebar;