import React from 'react';
import TeamMemberCard from '../ui/TeamMemberCard';

const AboutUs = () => {
  const teamMembers = [
    {
      name: 'Ben',
      image: 'https://res.cloudinary.com/bettercast/image/upload/v1729765635/mkzt46hxhnqdvi5huq2x.jpg',
      description: 'Ben is a seasoned entrepreneur with a passion for building innovative solutions. With years of experience in product development and business strategy, he brings valuable insights to the Business Daddy team.',
      website: 'https://benjaminspowell.com',
      twitter: 'https://x.com/benjaminspowell',
      linkedin: 'https://www.linkedin.com/in/benjaminspowell',
      email: 'hello@businessdaddy.com',
    },
    {
      name: 'Hunter',
      image: 'https://res.cloudinary.com/bettercast/image/upload/v1729765554/r30yk99i8b2oi6e1alo6.jpg', // Use the imported variable here
      description: 'Hunter is a talented developer with a keen eye for user experience. His expertise in modern web technologies and agile methodologies helps drive the rapid development of our apps.',
      //website: 'https://hunter-website.com',
      //twitter: 'https://twitter.com/hunter',
      //linkedin: 'https://linkedin.com/in/hunter',
      //email: 'hunter@businessdaddy.com',
    },
    {
      name: 'ChatGPT',
      image: 'https://res.cloudinary.com/bettercast/image/upload/v1719840497/kjyizaey3bi9yiputaar.png',
      description: 'ChatGPT is our AI collaborator, providing invaluable assistance in ideation, problem-solving, and content creation. It helps streamline our development process and brings a unique perspective to our projects.',
      website: 'https://openai.com/chatgpt',
      //twitter: 'https://twitter.com/openai',
    },
  ];

  return (
    <section id="about" className="py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">About Business Daddy</h2>
          <p className="max-w-4xl mx-auto text-gray-700 text-lg">
            Business Daddy is a joint collaboration project between Ben, Hunter, and ChatGPT. Our
            goal is to build 20 apps in 20 weeks, testing if it's possible to solve singular problems
            while generating revenue to sustain our lifestyle.
          </p>
          <p className="max-w-4xl mx-auto text-gray-700 text-lg mt-4">
            Every app is built in true MVP style, focusing on solving one problem for about $5 per month.
          </p>
        </div>

        <h3 className="text-3xl font-semibold text-center mb-12">Meet Our Team</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamMembers.map((member, index) => (
            <TeamMemberCard key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;