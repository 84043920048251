import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../../firebase';
import { collection, addDoc, updateDoc, doc, getDocs } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CloudinaryUpload from '../../services/Cloudinary';

const CreateGuideForm = ({ guide, closeModal, refreshGuides }) => {
  const [title, setTitle] = useState(guide?.title || '');
  const [content, setContent] = useState(guide?.content || '');
  const [projectId, setProjectId] = useState(guide?.projectId || '');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const quillRef = useRef(null); // Reference to access Quill editor

  // Fetch projects to populate the select dropdown
  useEffect(() => {
    const fetchProjects = async () => {
      const projectCollection = await getDocs(collection(firestore, 'projects'));
      setProjects(projectCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchProjects();
  }, []);

  // Custom image handler for Quill
  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const imageUrl = await CloudinaryUpload(file); // Upload to Cloudinary and get the URL
      const quill = quillRef.current.getEditor(); // Get the editor instance
      const range = quill.getSelection();
      quill.insertEmbed(range.index, 'image', imageUrl); // Insert the image at the current cursor position
    };
  };

  const quillModules = {
    toolbar: {
      container: [
        ['bold'], 
        [{ 'list': 'bullet' }], 
        ['link', 'image'] // Add image option to the toolbar
      ],
      handlers: {
        image: handleImageUpload // Custom handler for image uploads
      }
    }
  };

  const quillFormats = ['bold', 'list', 'link', 'image']; // Enable image format

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (guide) {
        // Update guide
        await updateDoc(doc(firestore, 'guides', guide.id), {
          title,
          content,
          projectId,
        });
      } else {
        // Create new guide
        await addDoc(collection(firestore, 'guides'), {
          title,
          content,
          projectId,
          published: true, // Mark as published by default
        });
      }
      refreshGuides(); // Refresh guide list
      closeModal(); // Close the modal
    } catch (error) {
      console.error('Error saving guide:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">{guide ? 'Edit Guide' : 'Create New Guide'}</h2>

      {/* Project Select */}
      <div className="mb-4">
        <label className="block text-gray-700">Project</label>
        <select
          value={projectId}
          onChange={(e) => setProjectId(e.target.value)}
          className="w-full p-2 border rounded-lg"
          required
        >
          <option value="">Select a project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.title}
            </option>
          ))}
        </select>
      </div>

      {/* Title */}
      <div className="mb-4">
        <label className="block text-gray-700">Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 border rounded-lg"
          placeholder="Enter guide title"
          required
        />
      </div>

      {/* Content with Quill */}
      <div className="mb-8">
        <label className="block text-gray-700 mb-2">Content</label>
        <div className="border rounded-lg p-2">
          <ReactQuill
            ref={quillRef} // Attach Quill editor reference
            value={content}
            onChange={setContent}
            modules={quillModules}
            formats={quillFormats}
            className="h-40"
            placeholder="Enter guide content"
            key={content} // Ensure the editor re-renders correctly
          />
        </div>
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full"
        disabled={loading}
      >
        {loading ? 'Saving...' : guide ? 'Update Guide' : 'Create Guide'}
      </button>
    </form>
  );
};

export default CreateGuideForm;